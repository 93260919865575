import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "react-seo-component"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import {
  Container,
  Content,
  FeaturedBackgroundImage,
  // usePortableTextComponents,
} from "../components"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { PopupBanner } from "@features"
import { usePortableTextComponents } from "@hooks"
import { PortableText } from "@portabletext/react"

export default function IndexPage({ data, location }) {
  // const { title, description } = useSiteMetadata()
  const [popupOpen, setPopupOpen] = useState(true)
  // console.log("[HomePage] data", data)

  // const {
  //   body,
  //   frontmatter: { featureImage },
  // } = data.mdx
  const { heroImage, _rawBody } = data.sanityPage

  const {
    title,
    description,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata()

  // const img = featureImage.childImageSharp.gatsbyImageData
  const img = heroImage?.asset?.gatsbyImageData

  const { pathname } = location
  // console.log("location:", location)
  // console.log("location.pathname:", pathname)

  const slug = pathname
  const portableComponents = usePortableTextComponents(slug || "home")

  const {
    allSanityPopup: { edges: popupData },
  } = data
  // console.log("[Homepage template] popupData:", popupData)
  const activePopupExists = popupData.find(popup => popup.node.popupEnabled)
  // console.log("[Homepage template] activePopupExists:", activePopupExists)

  return (
    <>
      <Seo
        title={"We are RECS"}
        titleTemplate={title}
        description={description}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <Container pathname={pathname}>
        {activePopupExists && popupOpen && (
          <PopupBanner
            setOpenPopup={setPopupOpen}
            popupData={activePopupExists}
          />
        )}
        <FeaturedBackgroundImage
          pathname={pathname}
          opacity={0.7}
          image={img}
          title={"We are RECS"}
          subtitle="Recreate &#8226; Exercise &#8226; Compete &#8226; Socialize"
          subimage={data.logo.publicURL}
        />
        <Content pathname={pathname}>
          {/* <BldgRendering /> */}
          {/* <MDXRenderer>{body}</MDXRenderer> */}
          <PortableText
            value={_rawBody}
            // components={myPortableTextComponents}
            components={portableComponents}
          />
        </Content>
      </Container>
    </>
  )
}

// export const query = graphql`
//   query HOME_PAGE_QUERY {
//     mdx(slug: { eq: "home/" }) {
//       id
//       slug
//       body
//       frontmatter {
//         title
//         featureImage {
//           childImageSharp {
//             gatsbyImageData(
//               placeholder: BLURRED
//               layout: FULL_WIDTH
//               formats: [AUTO, WEBP]
//               transformOptions: { fit: COVER, cropFocus: ATTENTION }
//             )
//           }
//         }
//       }
//     }
//     logo: file(relativePath: { eq: "recsAsset_7_whiteText.svg" }) {
//       publicURL
//     }
//   }
// `

export const query = graphql`
  query HOME_PAGE_QUERY {
    sanityPage(slug: { current: { eq: "home" } }) {
      id
      _rawBody(resolveReferences: { maxDepth: 10 })
      title
      slug {
        current
      }
      heroImage {
        _key
        _type
        caption
        alt
        _rawAsset
        _rawHotspot
        _rawCrop
        asset {
          id
          gatsbyImageData
        }
      }
    }
    logo: file(relativePath: { eq: "recsAsset_7_whiteText.svg" }) {
      publicURL
    }
    allSanityPopup {
      edges {
        node {
          ...SanityPopup
        }
      }
    }
  }
`
